import styled from 'styled-components'
const Page = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: justify;
  word-break: break-all;
  padding-bottom: 7rem;
  height: 100vh;
  background-color: ${(props) => props.bg};
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  && h1 {
    font-size: 5rem;
    color: black;
  }
  && #show {
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    padding-left: 50px;
    justify-content: flex-start;
    color: ${(props) => props.color};
  }
  && #show iframe {
    margin-top: 1rem;
    max-width: 640px;
    filter: url(#duotone);
  }
  && #show iframe:hover {
    filter: none;
  }
  && #show svg#close {
    position: absolute;
    top: 50px;
    right: 50px;
    fill: aqua;
    //fill: ${(props) => props.color};
    stroke: ${(props) => props.color};
    z-index: 1000;
  }
  && #show svg#close:hover {
    fill: red;
  }
  && #show h2 {
    color: beige;
  }
  && #show a {
    color: aqua;
    text-decoration: none;
  }
  && #show a:hover {
    background-color: red;
    color: black;
    text-decoration: none;
  }
`
const CenteredPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
`

const Navigation = styled.nav`
  @media (min-width: 1024px) {
    width: 100vw;
    height: 114px;
    clip-path: polygon(1% 0, 30% 25%, 85% 15%, 89% 100%, 0 100%);
    display: flex;
    overflow: hidden;
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 1000;
    mix-blend-mode: hard-light;
    opacity: 0.9;
    && a {
      margin: 0;
      padding: 0;
      text-decoration: none;
      width: auto;
    }
    && div {
      width: 66px;
      height: 184px;
      background-repeat: no-repeat;
      background-image: url(${process.env.PUBLIC_URL}/img/nav/navmen.png);
      transition: transform 100ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
      cursor: pointer;
    }
    && div:hover {
      transform: translateY(-58px);
    }
    && div.active:hover {
      transform: none;
    }
    && div:nth-child(1) {
      background-position: 0px 0px;
      width: 226px;
      background-image: url(${process.env.PUBLIC_URL}/img/nav/nav_mainl.png);
      transition: none;
      padding-left: 2rem;
      padding-top: 0.3rem;
      box-sizing: border-box;
      font-size: 1.9rem;
    }
    && div:nth-child(1):hover {
      transform: none;
    }
    && span {
      flex-grow: 1;
      background-color: black;
      font-size: 5rem;
      padding: 1rem 1rem;
    }
  }
`

const SplashPage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: justify;
  word-break: break-all;
  padding-bottom: 7rem;
  height: 100vh;
  background-color: ${(props) => props.bg};
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  && svg {
    width: 100%;
    heigth: 100%;
    fill: black;
  }
`

const BioPage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  width: 100vw;
  box-sizing: border-box;
  overflow: scroll;
  color: black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 10vh;
  filter: contrast(300%);

  && section {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
  }
  && main {
    position: relative;
    width: 50%;
    min-height: 100vh;
    flex-grow: 1;
    box-sizing: border-box;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 1rem;
    filter: saturate(0%);
  }
  && aside {
    width: 50%;
    height: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1rem;
  }
  && main .foto {
    color: aqua;
    background-color: black;
    padding: 0rem 0.5rem;
    font-size: 1rem;
    margin: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  && p {
    color: black;
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: right;
    max-width: 50rem;
  }
  && h1 {
    color: black;
    margin: 0;
  }
  && a {
    color: aqua;
    text-decoration: underline overline;
  }
  && a:hover {
    background: aqua;
    color: black;
  }
`

const ContactPage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: cover;
  background-position: 30% 30%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  text-align: center;
  word-break: break-all;
  padding: 1rem;
  padding-top: 4rem;
  //height: calc(100vh - 114px);
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  && #img {
    position: relative;
  }
  /*
  && #img::after {
    position: absolute;
    content: 'photo by Oli Knoth';
    bottom: 10px;
    right: 0px;
    color: white;
    writing-mode: tb-rl;
}
*/
  && #link {
    width: 40vw;
    height: 100vh;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  && #link svg {
    height: 300px;
    margin: 0;
    margin-top: 2rem;
  }
  && li {
    background-color: black;
    margin: 0;
    font-size: 2rem;
    display: block;
    padding: 0 0.2rem;
    transform: rotate(-30deg);
  }

  && p.addr {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
    writing-mode: tb-rl;
    color: ${(props) => props.color};
    padding: 0.5rem 0rem;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  && h4 {
    margin: 0;
    background-color: black;
    transform: rotate(-30deg);
  }
  && p.foto {
    background-color: black;
    padding: 0rem 0.5rem;
    font-size: 1rem;
    margin: 0;
    position: absolute;
    right: -60px;
    bottom: 90px;
    transform: rotate(90deg);
  }
  && a {
    text-decoration: underline overline;
    background-color: ${(props) => props.color};
    color: black;
  }
  && a:hover {
    color: ${(props) => props.color};
    background-color: black;
  }
`
const DiscogsPage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: justify;
  word-break: break-all;
  padding: 1rem;
  padding-bottom: 7rem;
  //height: calc(100vh - 114px);
  height: 100vh;
  background-color: ${(props) => props.bg};
  width: 100vw;
  box-sizing: border-box;
  overflow: scroll;
`

const LinksPage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: justify;
  word-break: break-all;
  margin: 0;
  padding-bottom: 7rem;
  //height: calc(100vh - 114px);
  height: 100vh;
  background-color: ${(props) => props.bg};
  width: 100vw;
  box-sizing: border-box;
  overflow: scroll;
  padding-left: 0.5rem;

  && a.links {
    font-size: 8rem;
    font-weight: 900;
    line-height: 3.5rem;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    cursor: none;
  }
  && a.links:hover {
    color: aquamarine;
  }
`
const Iframe = styled.iframe`
  width: 100vw;
  //height: calc(100vh - 114px);
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0;
  box-sizing: border-box;
  border: none;
`
const H1 = styled.h1`
  color: black;
  font-size: 4rem;
  margin: 0;
`

const UL = styled.ul`
  list-style-type: none;
  margin-bottom: 10rem;
  font-size: 1.5rem;
  color: black;
  width: calc(100vw);
  margin: 0;
  padding: 1rem;
  padding-top: 0px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  scroll-behavior: smooth;
  && li {
    mix-blend-mode: hard-light;
  }
  && li span {
    margin-right: 2rem;
  }
  && li:nth-child(odd) {
    background-color: blueviolet;
  }
  && li:nth-child(even) span {
    color: red;
  }
  && li span:nth-child(even) {
    font-weight: 900;
    margin-right: 3rem;
  }
  && svg {
    fill: black;
  }
  && svg:hover {
    fill: fuchsia;
  }
`

const Title = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21vw;
  line-height: 14vw;
  text-align: center;
  color: ${(props) => props.fgcolor};
  cursor: pointer;
  box-sizing: border-box;
  z-index: 10;
  /*
  @media (min-width: 1024px) {
    font-size: 18rem;
    line-height: 12rem;
  }
  @media (min-width: 1240px) {
    font-size: 22rem;
    line-height: 15rem;
}
*/
`

const Albums = styled.section`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;
  background-color: black,
  color: white;

  && div.card {
    flex-basis: 33vw;
    padding: 1rem;
    padding-bottom: 2rem;
  }
  && div.container {
    padding: 1rem;
    height: auto;
  }
  && h4 {
      margin-bottom: .5rem;
    }
  && div.container p {
    width: 100%;
    margin-bottom: .5rem;
  }
  && div.container a {
    color: aqua;
    text-decoration: underline overline;
  }
  && div.container a:hover {
    color: black;
    background: aqua;
  }
`

const NewsPage = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  scroll-snap-type: mandatory;
  background: repeating-linear-gradient(
    -45deg,
    #000000,
    #000000 10px,
    transparent 10px,
    transparent 20px
  );
  box-sizing: border-box;
  && iframe {
    box-sizing: border-box;
    width: 100%;
    box-sizing: border-box;
    scroll-snap-align: start;
    background: repeating-linear-gradient(
      45deg,
      #000000,
      #000000 10px,
      blueviolet 10px,
      blueviolet 20px
    );
  }
`
const Essential = styled.section`
  background-repeat: no-repeat;
  padding: 1rem;
  border: 10px solid ${(props) => props.color};
  box-sizing: border-box;
  font-size: 1.4rem;
  && a {
    padding: 1rem;
    display: inline-block;
    font-size: 2rem;
    text-decoration: none;
    background-color: white;
    color: ${(props) => props.color};
  }
  && a:hover {
    background-color: ${(props) => props.color};
    color: ${(props) => props.color2};
    //mix-blend-mode: multiply;
  }
  && span.essential {
    font-size: 1.7rem;
  }
  && span.sudden {
    font-size: 5.5rem;
    line-height: 3rem;
    font-weight: 800;
    /*
    @media (max-width: 1520px) {
      font-size: 9rem;
      line-height: 7rem;
}
*/
  }
  && span.joke {
    font-size: 3.4rem;
    line-height: 6.2rem;
    font-weight: 800;
    /*
    @media (max-width: 1520px) {
      font-size: 5.5rem;
      line-height: 3.2rem;
}
*/
  }
`
export {
  Navigation,
  Page,
  CenteredPage,
  UL,
  Iframe,
  H1,
  LinksPage,
  ContactPage,
  DiscogsPage,
  BioPage,
  Title,
  SplashPage,
  Albums,
  NewsPage,
  Essential,
}
