import Marquee from 'react-easy-marquee'
import useSWR, { SWRConfig } from 'swr'
import React from 'react'
import './marquee.css'

function randomNumberInRange(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const fetcher = (...args) => fetch(...args).then((res) => res.json())

const Example = () => {
  const url = '/data/marquee.json?data=' + randomNumberInRange(0, 100)
  const { data, error } = useSWR(url, fetcher)

  if (error) {
    return (
      <Marquee height="50px" background="aqua">
        <img src="http://suddeninfant.com/img/disko.gif" alt="" height="50" />
        <h2>Error: {error.toString()}</h2>
      </Marquee>
    )
  }
  if (!data) {
    return (
      <Marquee height="50px" background="aqua">
        <img src="http://suddeninfant.com/img/disko.gif" alt="" height="50"/>
        <h2>fetching data...</h2>
      </Marquee>
    )
  }

  console.log(data, error)
  return (
    <>
      <Marquee
        reverse={true}
        duration={30000}
        pauseOnHover={true}
        background="red"
        height="50px"
      >
        {data.map(function(concert) {
          return (
            <a
              className="announcement"
              href={concert.url}
              target="_blank"
              rel="noreferrer"
            >
              <div className="newdate">
                <span className="date">{concert.date}</span>
            {concert.performers} @ {concert.venue} / {concert.town}
              </div>
            </a>
          )
        })}
      </Marquee>
    </>
  )
}

export default Example
