import React from 'react'
import styled from 'styled-components'

const Item = styled.div`
  margin: 0.1rem;
  background: 
  /* On "top" */ repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
rgba(255,255,255,0.2) 10px,
 rgba(255,255,255,0.2) 20px
  );
//border: 5px solid rgba(0, 0, 0, 1);
  display: flex;
  && img {
    margin-right: 1rem;
    min-height: 200px;
    width: auto;
  }
  && footer h2 {
    font-size: 1.5rem;
  }
  && footer h2.card_title {
    display: inline-block;
    background-color: crimson;
    margin-right: 1rem;
  }
  && footer h2.card_artist {
    display: inline-block;
    background-color: aqua;
    color: black;
  }
`

const CARD = props => {
  return (
    <Item
      key={props.title}
      style={{width: props.colWidth}}
      className="grid-item">
      {props.thumb && (
        <img src={props.thumb} alt="" loading="lazy" className="duotone" />
      )}
      <footer>
        <h2 className="card_title hvr-bounce-to-left">{props.title}</h2>
        <h2 className="card_artist hvr-bounce-to-left">{props.artist}</h2>
        <h2 className="card_subtitle hvr-bounce-to-left">{props.year}</h2>
        <h2 className="card_subtitle hvr-bounce-to-left">{props.format}</h2>
      </footer>
    </Item>
  )
}
export default CARD
