import React, { useEffect } from 'react'
import useSWR, { SWRConfig } from 'swr'
import { CenteredPage, LinksPage } from '../styled.js'


  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


const fetcher = (...args) => fetch(...args).then((res) => res.json())
//fetching data
const Links = () => {
  const url = '/data/links.json?data='+randomNumberInRange(0,100)
  //const url = 'http://localhost/suddeninfant/back/data/links.json'
  const { data, error } = useSWR(url, fetcher)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //console.log(data, error)
  if (error) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>Error: {error.toString()}</h1>
      </CenteredPage>
    )
  }
  if (!data) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>loading...</h1>
      </CenteredPage>
    )
  }
  return (
    <LinksPage>
      {data.map((item) => {
        return (
          <a
            key={item.name}
            className="links"
            rel="noopener noreferrer"
            target="_blank"
            href={item.url}
          >
            {item.name}
          </a>
        )
      })}
      <div id="shim"></div>
    </LinksPage>
  )
}
export default function Discogs() {
  return (
    // global provider for swr
    <SWRConfig value={{ fetcher }}>
      <Links />
    </SWRConfig>
  )
}
