import React from 'react'
import {ContactPage} from '../styled.js'

const Contact = props => {
  const color = props.color

  return (
    <ContactPage
      color={color}
      style={{
        backgroundImage:
          'url(http://www.suddeninfant.com/img/contact.jpg)',
      }}>
      <section id="link">
        <svg
          viewBox="0 0 800 600"
          xmlns="http://www.w3.org/2000/svg"
          fill="blueviolet"
        >
          <path
            d="M5.237 3.666l.22 589.482 294.632-294.63L5.237 3.667zM316.656 3.666l.22 589.482 294.632-294.63L316.656 3.667z"
            stroke="black"
            strokeWidth="5"
          />
        </svg>

        <p className="addr">
          <a href="https://www.instagram.com/jokelanz/">
            instagram 
          </a>
          <a href="https://t.me/suddeninfant">
            telegram  
          </a>
          <a href="https://www.facebook.com/SuddenInfant">
            facebook  
          </a>
          <a href="mailto:joke@suddeninfant.com">
            joke[at]suddeninfant[dot]com
          </a>
        </p>
      </section>
      <br />
      <h4 style={{color: color}}>Available for:</h4>
      <ul style={{color: color}}>
        <li>Lectures</li>
        <li>Workshops</li>
        <li>Performances</li>
        <li>Radio Interviews</li>
        <li>Installation</li>
        <li>Turntablism</li>
      </ul>
      <p className="foto" style={{color: color}}>
        photo by Cristina Marx 
      </p>
    </ContactPage>
  )
}

export default Contact
