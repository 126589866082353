import React, {useEffect} from 'react'
import useSWR, {SWRConfig} from 'swr'
import {CenteredPage, Albums} from '../styled.js'



  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


const fetcher = (...args) => fetch(...args).then(res => res.json())
//fetching data
const Releases = ({action}) => {
  const url = '/data/releases.json?data='+randomNumberInRange(0,100)
const {data, error} = useSWR(url, fetcher)
useEffect(() => { window.scrollTo(0, 0) }, [])

//console.log(data, error)
  if (error) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>Error: {error.toString()}</h1>
      </CenteredPage>
    )
  }
  if (!data) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>loading...</h1>
      </CenteredPage>
    )
  }
  return (
    <Albums style={{backgroundColor: 'black', color: 'white'}}>
      {data.map(item => {
        return (
          <div className="card" key={item.title}>
            <img src={item.img} alt="Avatar" style={{width: '100%'}} />
            <div className="container">
              <h4>
                <b>
                  {item.artist}<br />
                  {item.title}<br />
                  {item.tontraeger}
                </b>
              </h4>
              {item.text.map(function(item) {
                return <p key={item.paragraph}>{item.paragraph}</p>
              })}
              {item.links.map(function(link) {
                return <span key={link.title}><a href={link.url} target="_blank" rel="noreferrer"> {link.title} </a><br /></span>
              })}
            </div>
          <div id="shim"></div>
          </div>
        )
      })}
          <div className="card">
            <div className="container">
              <h4>
                  <a href="https://www.discogs.com/artist/75331-Sudden-Infant" target="_blank" rel="noreferrer"> <b> full discography on discogs </b> </a>
              </h4>
            </div>
          </div>
        </Albums>
  )
}
export default function Discogs() {
  return (
    // global provider for swr
    <SWRConfig value={{fetcher}}>
      <Releases />
    </SWRConfig>
  )
}
