import React from 'react'
import styled, { keyframes } from 'styled-components'

const barberpole = keyframes`
  100% {
    background-position: -500% -500%;
  }
`

const SplashPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: repeating-linear-gradient(
    45deg,
    black,
    black 20px,
    white 20px,
    white 40px
  );
  background-size: 500% 500%;
  animation-name: ${barberpole};
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  && img {
    height: 70%;
    transition: transform 100ms ease-in;
    cursor: pointer;
  }
  img:hover {
    transform: scale(1.2);
  }
  div img:hover {
    transform: scale(1.2);
  }
  section {
    max-width: 30%;
    padding: 1rem;
    //background: rgb(255, 253, 242);
    background: black;
    color: rgb(197, 49, 53);
  }
  section div {
    //width: 400px;
    display: inline-block;
    max-height: 300px;
  }
  section .div-caption {
    color: rgb(197, 49, 53);
    background-color: black;
    font-size: 2rem;
    z-index: 100;
    width: 100%;
    text-align: center;
    pointer-events: none;
  }
  a {
    color: rgb(197, 49, 53);
  }
  section:hover,
  section:hover a {
    color: rgb(255, 253, 242);
    background: rgb(197, 49, 53);
  }
  section:hover em {
    color: rgb(255, 253, 242);
  }
  em {
    color: rgb(197, 49, 53);
    line-height: 3.2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: inline-block;
    box-sizing: border-box;
    font-size: 5rem;
    font-weight: bolder;
    //font-family: Impact, sans-serif;
  }
`

const SPLASH = () => {
  const gotoBandcamp = () => {
    window.location.href = 'https://suddeninfant.bandcamp.com/'
  }

  return (
    <SplashPage>
      <section>
        <a href="https://suddeninfant.bandcamp.com/">
          {' '}
          <em>
            Lunatic
            <br /> Asylum
          </em>
          <br />
          <div style={{ border: 'none' }}>
            <img
              src="https://f4.bcbits.com/img/0013495823_23.jpg"
              alt="on bandcamp"
              style={{ width: '500px' }}
            />
          </div>
        </a>
        <br />
        <a href="https://suddeninfant.bandcamp.com/">
          on <b>bandcamp</b> <br />
        </a>
        <br />
        <a href="https://fourthdimensionrecords.bigcartel.com/product/sudden-infant-lunatic-asylum-cd">
          on <b>Fourth Dimension Records</b> <br />
        </a>
        <br />
        Release date: July 22!
        <br />
        <br />
        Features a guest appearance by Franz Treichler (The Young Gods) and two
        additional tracks to the vinyl version that'll follow later in June.
      </section>
      <img
        onClick={gotoBandcamp}
        src="http://suddeninfant.com/news/img/lunatic_tour_2023.jpg"
        alt=""
      />
    </SplashPage>
  )
}

export default SPLASH
