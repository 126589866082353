import React, {useState, useEffect} from 'react'
import useSWR, {SWRConfig} from 'swr'
import {motion, useViewportScroll, useSpring, useTransform} from 'framer-motion'
import Masonry from 'react-masonry-css'
import {CenteredPage} from '../styled.js'
import './sideprojects.css'


  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

const fetcher = (...args) => fetch(...args).then(res => res.json())

const Projects = props => {
  const color = props.color
  const url = '/data/projects.json?data='+randomNumberInRange(0,100)
  //const url = 'http://localhost/suddeninfant/back/data/projects.json'
  const {data, error} = useSWR(url, fetcher)
  const [showCase, setShowCase] = useState(null)
  const [isComplete, setIsComplete] = useState(false)
  const {scrollYProgress} = useViewportScroll()
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1])
  const pathLength = useSpring(yRange, {stiffness: 400, damping: 90})
  const toTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() =>  yRange.onChange(v => setIsComplete(v >= 1)), [yRange])
//console.log(data)

  if (error) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>Error: {error.toString()}</h1>
      </CenteredPage>
    )
  }
  if (!data) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>loading...</h1>
      </CenteredPage>
    )
  }
  return (
    <>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {data.map((project, i) => {
          return (
            <div
              onClick={() => setShowCase(i)}
              key={i}
              style={{
                backgroundImage: 'url(/img/projects/' + project.img + ')',
                height: project.height,
              }}>
              <span
                className="title"
                style={{color: color, backgroundColor: 'white'}}>
                {project.name}
              </span>
            </div>
          )
        })}
      </Masonry>
      {showCase !== null && (
        <div id="show">
          <svg
            onClick={() => setShowCase(null)}
            id="close"
            viewBox="0 0 512 512"
            height="60"
            width="60"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" />
          </svg>
          <h2>{data[showCase].name}</h2>
          {data[showCase].members.map(member => {
            return !member.link ? (
              <h2>
                {member.name} : {member.instr}
              </h2>
            ) : (
              <h2>
                <a href={member.link} target="_blank" rel="noreferrer">
                  {member.name} : {member.instr}
                </a>
              </h2>
            )
          })}
          {!data[showCase].link ? (
            <span>( xxx )</span>
          ) : (
            <iframe
              title="Projects Video"
              width="100%"
              height="500"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src={data[showCase].link}
            />
 )}

        <div id="shim"></div>
        </div>
      )}
      <svg
        onClick={() => toTop()}
        className="progress-icon"
        viewBox="0 0 60 60">
        <motion.path
          fill="none"
          strokeWidth="5"
          stroke={color}
          strokeDasharray="0 1"
          d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
          style={{
            pathLength,
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1, // Reverse direction of line animation
          }}
        />
        <motion.path
          fill="none"
          strokeWidth="5"
          stroke={color}
          d="M14,26 L 22,33 L 35,16"
          initial={false}
          strokeDasharray="0 1"
          animate={{pathLength: isComplete ? 1 : 0}}
        />
      </svg>
      <div className="gone">
        {' '}
        <svg xmlns="http://www.w3.org/2000/svg" className="svg-filters">
          <filter id="duotone">
            <feColorMatrix
              type="matrix"
              result="grayscale"
              values="1 0 0 0 0
                      1 0 0 0 0
                      1 0 0 0 0
                      0 0 0 1 0"
            />
            <feComponentTransfer
              colorInterpolationFilters="sRGB"
              result="duotone_projects">
              <feFuncR type="table" tableValues="0 1" />
              <feFuncG type="table" tableValues="0 0.8" />
              <feFuncB type="table" tableValues="0 0.6" />
              <feFuncA type="table" tableValues="0 1" />
            </feComponentTransfer>
          </filter>
          <filter id="duotone_inv">
            <feColorMatrix
              type="matrix"
              result="grayscale"
              values="1 0 0 0 0
                      1 0 0 0 0
                      1 0 0 0 0
                      0 0 0 1 0"
            />
            <feComponentTransfer
              colorInterpolationFilters="sRGB"
              result="duotone_projects">
              <feFuncR type="table" tableValues="1 0" />
              <feFuncG type="table" tableValues="0.8 0" />
              <feFuncB type="table" tableValues="0.6 0" />
              <feFuncA type="table" tableValues="0 1" />
            </feComponentTransfer>
          </filter>
        </svg>
      </div>
    </>
  )
}

export default function Live() {
  return (
    // global provider for swr
    <SWRConfig value={{fetcher}}>
      <Projects />
    </SWRConfig>
  )
}
