import React from 'react'
import styled from 'styled-components'

const SplashPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: black;
  background-size: cover;
  background-position: center center;
  padding: 0;
  color: rgb(197, 49, 53);
  cursor: pointer;
  backdrop-filter: blur(10px);
  animation: fadein 2s;
  && img {
    height: 450px;
  }
  && p {
    width: 30%;
    background: rgba(255, 253, 242, 0.5);
    padding: 0.3rem;
    text-align: center;
  }

  && div#sp_container {
    transition: all 0.2s ease-in-out;
  }
  && div#sp_container:hover {
    transform: scale(1.1);
box-shadow: 3px 3px 5px rgb(0,0,0,0.2);
    && h1 {
      text-shadow: -10px 0px 0px transparent, 10px 0px 0px transparent,
        0px -10px 0px transparent, 0px 10px 0px black;
    }
  }
  && div#title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 1rem;
  }
  && h1 {
    flex-basis: 100%;
    font-size: 2rem;
    color: white;
    font-family: sans-serif;
    font-weight: 100;
    text-shadow: -1px 0px 0px transparent, 1px 0px 0px transparent,
      0px -1px 0px transparent, 0px 1px 0px black;
  }
  && h2.announcement {
    font-size: 2rem;
    color: aqua;
    font-family: inherit;
    font-weight: bold;
  }
  && h2 {
    font-size: 2rem;
    color: black;
    font-family: sans-serif;
    font-weight: bol;
  }
`

const SPLASH = (props) => {
  return (
    <SplashPage onClick={props.click}>
      <div id="sp_container">
        <img src="http://suddeninfant.com/news/img/joke_live.jpg" alt="" />
      </div>
    </SplashPage>
  )
}

export default SPLASH
