import React from 'react'
import styled from 'styled-components'

const SplashPage2 = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(http://suddeninfant.com/news/img/animals_cover_bg.jpg);
  background-position: center center;
  background-size: cover;
  color: white;
  position: relative;
  && img {
    height: 300px;
    width: 300px;
    transition: transform 100ms ease-in;
  }
  && img#poster {
width: 412px;
    max-width: 412px;
  }
  && img#agnel_lanz_vatcher {
width: 412px;
    max-width: 412px;
  }
  img:hover {
    //transform: scale(1.2);
  }
  div {
    height: 300px;
  }
  section {
    max-width: 90%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section > * {
    flex-basis: 1;
  }
  && div.txt {
    padding: 1rem;
    background: rgb(255, 255, 255, 0.4);
    background: rgba(189, 188, 47, 0.4);
    color: black;
    width: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 95%);
    border-right: 1px solid aqua;
    box-sizing:border-box;
  }
  && div.#animals_player {
    background-image: url(http://suddeninfant.com/news/img/animals_cover.jpg);
    background-position: center center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  && .leftBorder {

    border-left: 1px solid aqua;

  }
  && a {
    color: white;
    background: black;
  }
  && a:hover {
    color: black;
    background: white;
  }
  && iframe {
    filter: saturate(60%);
  }
  && h1.li {
    margin: 0;
    font-size: 70px;
    color: black;
    position: absolute;
    left: 0px;
    top: calc(50% - 300px);
    color: aqua;
    color: rgb(137, 224, 244);
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
  && h1.re {
    margin: 0;
    font-size: 70px;
    color: black;
    position: absolute;
    right: 0px;
    top: calc(50% - 300px);
    color: rgb(189, 188, 47);
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
`

const SPLASH2 = () => {
  /*
  const gotoBandcamp = () => {
    window.location.href = 'https://suddeninfant.bandcamp.com/'
  }
  */

  return (
    <SplashPage2>
      <h1 className="li">RELEASE</h1>
      <section className="leftBorder">
        <div className="animals_player">
          <iframe
            style={{border: '0', width: '300px', height: '300px' }}
            src="https://bandcamp.com/EmbeddedPlayer/album=2164529438/size=large/bgcol=ffffff/linkcol=2ebd35/tracklist=false/transparent=true/"
            seamless
          >
            <a href="https://klanggalerie.bandcamp.com/album/animals">
              Animals by AGNEL LANZ VATCHER
            </a>
          </iframe>
        </div>
        <div>
          <img
            id="poster"
            src="http://suddeninfant.com/news/img/animals_poster.jpg"
            alt=""
          />
        </div>
      </section>
      <section>
        <div>
          <img
            id="agnel_lanz_vatcher"
            src="http://suddeninfant.com/news/img/agnel_lanz_vatcher.jpg"
            alt=""
          />
        </div>
        <div className="txt">
          <span style={{color: 'aqua', fontWeight: 'bold'}}>OUT NOW:</span>
          <br />
          <a href="http://www.klanggalerie.com/gg426">
            AGNEL / LANZ / VATCHER "Animals" CD+digital (Klanggalerie gg 426)
          </a>{' '}

          <br />
          The trio impresses with precise playing, intensity, spontaneity and a
          great sense of musical humour. Radical playing techniques, curiosity
          and the exploration of sound possibilities create constant tension.
          Their first trio release Animals brings together 13 tracks full of
          twists and turns, creative lightness and complex virtuosity. <br />
        </div>
      </section>
      <h1 className="re"></h1>
    </SplashPage2>
  )
}

export default SPLASH2
