import React, { useState, useEffect } from 'react'
import useSWR, { SWRConfig } from 'swr'
import { NewsPage, Essential } from '../styled.js'
import YoutubeVideos from '../Videos/'
import SPLASH from './Splash.js'
import SPLASH2 from './Splash2.js'
//Laufband für die Konzertankündigungen
import Announcements from '../Announcements/'
import NewsMarquee from '../Marquee/'
//import SWIPER from './swiper.js'
const ImageUrl = 'http://suddeninfant.com/news/img/sudden_infant.jpg'
const News = (props) => {
  //const SWIPEROn = 0

  const FeatureVidOn = 1
  const SplashOn = 1
  const Splash2On = 0
  const color = props.color
  const MarqueeOn = 1

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <NewsPage>
      {MarqueeOn ? <Announcements style={{zIndex: '99999'}}/> : null}
      {Splash2On ? <SPLASH2 /> : null}
      {/*SWIPEROn ? <SWIPER /> : null*/} 
      {SplashOn ? <SPLASH /> : null}
      {FeatureVidOn ? (
        <iframe
          title="Sudden Infant Playlist"
          className="front"
          id="yt_nihilism"
          src="https://www.youtube.com/embed/6eRI9fstgHI"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : null}
      <section
        style={{
          position: 'relative',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          background: 'black',
        }}
      >
        {/*
        <iframe
          title="youtube_1"
          className="front"
          src="https://www.youtube.com/embed/6eRI9fstgHI"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
      />
 */}
        <YoutubeVideos />
        <img
          style={{
            position: 'absolute',
            top: '30px',
            right: '10px',
            width: '200px',
          }}
          src="/img/svg/youtube.svg"
          alt=""
        />
      </section>
      <section style={{ position: 'relative', border: '10px solid #629795' }}>
        <img
          src="/img/MyLifeIsAGunshot_A4.jpg"
          alt=""
          style={{ width: '100%', marginBottom: '-1rem' }}
        />
        <p
          style={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            background: 'black',
            padding: '1rem',
            color: 'white',
          }}
        >
          <iframe
            title="My Life is a Gunshot"
            style={{ background: 'black' }}
            src="https://player.vimeo.com/video/371005995"
            width="640"
            height="268"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
          <a href="https://vimeo.com/371005995">My Life Is A Gunshot</a> from{' '}
          <a href="https://vimeo.com/user33876180">Cin&eacute;ma Copain</a> on{' '}
          <a href="https://vimeo.com">Vimeo</a>.
        </p>
        {/*
          <iframe
          title="youtube_2"
          className="front"
          id="yt_gunshot"
          src="https://www.youtube.com/embed/mjbtV35mFuQ"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
*/}
      </section>
      <Essential
        color={color}
        color2={'white'}
        style={{
          background: 'url(http://suddeninfant.com/news/img/joke_bw.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: '70% 0%',
        }}
      >
        <a
          href="https://jokelanz.bandcamp.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <span className="essential">
            <b>Essential</b> selection of
          </span>
          <br />
          <span className="sudden">
            {' '}
            Sudden <br /> Infant{' '}
          </span>
          <br />
          <span className="joke">+Joke Lanz</span>
          <span className="releases">
            <br /> releases now on <br /> Bandcamp
          </span>
        </a>
      </Essential>
      <section id="band" style={{ backgroundImage: 'url(' + ImageUrl + ')' }}>
        <p>
          <i> "Fucking awesome band! </i> - Steve Crittall / The Selecter, UK
          Subs, The Godfathers
          <br />
          <i>
            "a sound and approach I haven't experienced since Swans first made
            it over to the UK in the early 1980's."
          </i>
          - Steve Cammack / Muh Mur UK
          <br />
          <i>
            "each time it's a revelation in how to firstly captivate and then
            thrill an audience."
          </i>{' '}
          - Steve Underwood / Harbinger Sound UK
          <br />
          <i>
            {' '}
            "It's a fucking tight unit operating in the realm between pure magic
            and chemistry!"
          </i>{' '}
          - Richard Johnson / Adverse Effect Magazine
          <br />
          <i>"Sudden Infant go all the way. Nothing is held back!"</i>- Keith
          Moliné / The Wire UK
          <br />
          <i>"A real fistful of sturm und drang..."</i> - Daniel Blumin / WFMU
          <br />
          <i>"Buddhist Nihilism ist ein Spektakel durch und durch."</i>- Jens
          Broxtermann / Pretty In Noise
        </p>
        <small>photo by Martin Baumgartner</small>
        <div id="shim" />
      </section>
    </NewsPage>
  )
}

export default News
