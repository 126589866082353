import React from 'react'
import Masonry from 'react-masonry-component'
import YoutubePlayer from 'react-youtube'
import Drawer from 'rc-drawer'
import styled from 'styled-components'
import './drawer.css'
import './masonry.css'

const playlist =
  'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLanWq4HFmWvKHC_0vUxOU4JB-9zjx_Z4z&key=AIzaSyBeZlqyolgfjNRUVTq0lMv7kwuuKhxTXbs'

const Header = styled.div`
  height: 675px;
  width: 100vw;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Error = styled.div`
  height: 300px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
`
const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > div {
    width: 24px;
    height: 4px;
    background: white;
    position: absolute;
    left: 3px;
    top: 13px;
  }

  & > div:first-child {
    transform: rotate(45deg);
  }

  & > div:last-child {
    transform: rotate(-45deg);
  }
`

const masonryOptions = {
  transitionDuration: '1s',
  columnWidth: '.grid-item',
  itemSelector: '.grid-item',
  percentPosition: true,
}

export default class Works extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      requestFailed: false,
      videos: null,
      currVideo: null,
      open: false,
      width: null,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  updateWindowDimensions() {
    this.setState({ width: (window.innerWidth / 100) * 80 })
  }

  componentDidMount() {
    fetch(playlist)
      .then((response) => {
        if (!response.ok) {
          throw Error('Network request failed')
        }
        return response
      })
      .then((res) => res.json())
      .then(
        (res) => {
          this.setState({
            videos: res.items,
            currVideo: res.items[0].snippet.resourceId.videoId,
          })
        },
        () => {
          this.setState({
            requestFailed: true,
          })
        },
      )

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  extractTitle(str) {
    return str.slice(str.lastIndexOf('–') + 1, str.length)
  }

  extractArtist(str) {
    if (str.indexOf('–') !== str.lastIndexOf('–')) {
      return str.slice(str.indexOf('–') + 2, str.lastIndexOf('–') - 1)
    } else {
      return str.slice(0, str.lastIndexOf('–'))
    }
  }

  handleClick = (event) => {
    this.setState({
      currVideo: event.currentTarget.id,
      open: !this.state.open,
    })
  }

  render() {
    /* player vars */
    const opts = {
      width: this.state.width + 'px',
      height: (this.state.width / 16) * 9 + 'px',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        controls: 0,
      },
    }

    if (this.state.requestFailed)
      return (
        <Error>
          <p>
            Loading youtube data failed! Please try again / reload the page.
          </p>
        </Error>
      )
    if (!this.state.videos) {
      return (
        <Error>
          <p>Loading...</p>
        </Error>
      )
    }
    const video = this.state.videos.map((item, index) => {
      if (item.snippet.thumbnails && item.snippet.thumbnails.high.url) {
        return (
          <div
            onClick={this.handleClick}
            id={item.snippet.resourceId.videoId}
            key={index}
            className="grid-item"
          >
            <figure>
              <img
                src={item.snippet.thumbnails.high.url}
                alt={this.extractTitle(item.snippet.title)}
              />
              <header>
                <h1 className="video-title">
                  {this.extractTitle(item.snippet.title)}
                </h1>
              </header>
            </figure>
          </div>
        )
      }
    })

    return (
      <section className="video-page">
        {/*Drawer**********************************************/}

        <Drawer
          handler={false}
          open={this.state.open}
          onClose={this.handleClick}
          placement="top"
        >
          <Header onClick={this.handleClick}>
            <CloseButton onClick={this.handleClick}>
              <div />
              <div />
            </CloseButton>
            <YoutubePlayer
              videoId={this.state.currVideo}
              opts={opts}
              controls={true}
            />
          </Header>
        </Drawer>

        {/*//Drawer**********************************************/}
        <Masonry
          className={'gallery'} // default ''
          options={masonryOptions} // default {}
        >
          {video}
        </Masonry>
      </section>
    )
  }
}
