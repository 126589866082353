import React, {useState} from 'react'
import useSWR, {SWRConfig} from 'swr'
import Masonry from 'react-masonry-component'
import {CenteredPage, DiscogsPage} from '../styled.js'
import CARD from './Card.js'

const masonryOptions = {
  transitionDuration: '0.5s',
  columnWidth: '.grid-item',
  itemSelector: '.grid-item',
  percentPosition: true,
  cornerStampSelector: '.fixed-box',
  stamp: '.fixed-box',
}

const key = 'gPPbxugMXlbjJWJWcrnS'
const secret = 'dXZLptPgjyAtYQoBxIgRlyyGJUyRhmak'
const fetcher = (...args) => fetch(...args).then(res => res.json())
//fetching data
const Releases = () => {
  const [pageIndex, setPageIndex] = useState(1)
  const url = `https://api.discogs.com/artists/75331/releases?page=${pageIndex}&per_page=50&key=${key}&secret=${secret}`
  const {data, error} = useSWR(url, fetcher)

  console.log(data, error)
  if (error) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>Error: {error.toString()}</h1>
      </CenteredPage>
    )
  }
  if (!data) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>loading...</h1>
      </CenteredPage>
    )
  }
  return (
    <DiscogsPage>
      <Masonry options={masonryOptions}>
        {data.releases.map((release, i) => {
          return (
            release.role === 'Main' && (
              <CARD
                colWidth={'31.3vw'}
                title={release.title}
                artist={release.artist}
                thumb={release.thumb}
                year={release.year}
                label={release.label}
                format={release.format}
              />
            )
          )
        })}
      </Masonry>
      <nav className="disco">
        {pageIndex > 1 && (
          <button onClick={() => setPageIndex(pageIndex - 1)}>
            {' '}
            <small>⮂</small> prev
          </button>
        )}
        {pageIndex < data.pagination.pages && (
          <button onClick={() => setPageIndex(pageIndex + 1)}>
            next <small>⮀</small>{' '}
          </button>
        )}
        <br />
        <span className="info">
          <strong>Complete SUDDEN INFANT discography</strong> thanks to{' '}
          <strong>Bili Rubin</strong> ( data from <strong>discogs</strong> )
        </span>
      </nav>
    </DiscogsPage>
  )
}
export default function Discogs() {
  return (
    // global provider for swr
    <SWRConfig value={{fetcher}}>
      <Releases />
    </SWRConfig>
  )
}
