import React, { useState } from 'react'
import {QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Navigation, Page, H1 } from './components/styled.js'
import './mobile.css'

import Home from './components/Splash'
import News from './components/News/'
import Links from './components/Links/'
import Live from './components/GigArchive/'
import Projects from './components/Projects/'
import Contact from './components/Contact/'
import Biography from './components/Bio/'
import Releases from './components/Releases/'
import Discogs from './components/Discogs/'


const colors = [
  'coral',
  '#afafaf',
  'lightgreen',
  'rgb(255, 204, 153)',
  'aqua',
  'magenta',
  'yellow',
  'red',
]

const pageNames = [
  'NEWS!',
  'INFO',
  'RELEASES',
  'PROJECTS',
  'CALENDAR',
  'LINKS',
  'CONTACT',
  'DISCOGS',
]

const Nav = () => {
  const color = colors[0]
  const [entered, setEntered] = useState(false)
  const [bgColor, setBgColor] = useState(color)
  const [pageName, setPageName] = useState(pageNames['NEWS!'])
  const [currPage, setCurrPage] = useState('')
  const [currColor, setCurrColor] = useState(colors[0])
  const [on, setOn] = useState(false)
  const setHoverFX = (col, name, bool) => {
    setBgColor(col)
    setPageName(name)
    setOn(bool)
  }
  const setClickFX = (num) => {
    setCurrColor(colors[num])
    setCurrPage(pageNames[num])
    setBgColor(colors[num])
  }
  const Enter = () => {
    setCurrPage('NEWS!')
    setEntered(true)
    mika()
  }
  const setDiscogs = () => {
    setClickFX(7)
  }
  let audio = new Audio('/audio/mika.mp3')

  const mika = () => {
    // audio.play()
  }
  const client =  new QueryClient()

  function setComponent(cp) {
    switch (cp) {
      case 'NEWS!':
        return (
          <Page bg={currColor}>
            <News color={currColor} />
          </Page>
        )
      /*
        return <Iframe src="http://NEWinfant.com/news/news.html" />
        return (
          <Page bg={currColor}>
            <Example />
          </Page>
        )
        */
      case 'INFO':
        return (
          <Page bg={currColor}>
            <Biography />
          </Page>
        )
      case 'CONTACT':
        return (
          <Page color={currColor}>
            <Contact color={currColor} />
          </Page>
        )
      case 'RELEASES':
        return (
          <Page bg="black">
            <Releases color={currColor} action={setDiscogs} />
          </Page>
        )
      case 'DISCOGS':
        return (
          <Page bg="black">
            <Discogs color={currColor} />
          </Page>
        )
      case 'LINKS':
        return (
          <Page bg={currColor}>
            <Links />
          </Page>
        )
      case 'CALENDAR':
        return (
          <Page bg={currColor}>
            <Live color={currColor} />
          </Page>
        )
      case 'PROJECTS':
        return (
          <Page bg={currColor}>
            <Projects color={currColor} />
          </Page>
        )
      default:
        return (
          <Page bg={currColor}>
            <H1>{currPage}</H1>
          </Page>
        )
    }
  }
  return entered === true ? (
    <QueryClientProvider client={client}>
      {setComponent(currPage)}
      <Navigation>
        <div
          onMouseEnter={() => setHoverFX(colors[0], pageNames[0], true)}
          onMouseLeave={() => setHoverFX(currColor, currPage, false)}
          onClick={() => setClickFX(0)}
          style={
            on
              ? {
                  backgroundImage: 'url(/img/nav/nav_mainr.png)',
                  backgroundColor: bgColor,
                  color: currColor,
                }
              : {
                  backgroundImage: 'url(/img/nav/nav_mainl.png)',
                  backgroundColor: bgColor,
                  color: currColor,
                }
          }
        >
          {currPage}
        </div>
        <div
          onMouseEnter={() => setHoverFX(colors[1], pageNames[1], true)}
          onMouseLeave={() => setHoverFX(currColor, currPage, false)}
          onClick={() => setClickFX(1)}
          style={{
            backgroundPosition:
              currPage === pageNames[1] ? '0px -58px' : '0px 0px',
            backgroundColor: colors[1],
          }}
          className={currPage === pageNames[1] ? 'active' : 'inactive'}
        />
        <div
          onMouseEnter={() => setHoverFX(colors[2], pageNames[2], true)}
          onMouseLeave={() => setHoverFX(currColor, currPage, false)}
          onClick={() => setClickFX(2)}
          style={{
            backgroundPosition:
              currPage === pageNames[2] ? '-66px -58px' : '-66px 0px',
            backgroundColor: colors[2],
          }}
          className={currPage === pageNames[2] ? 'active' : 'inactive'}
        />
        <div
          onMouseEnter={() => setHoverFX(colors[3], pageNames[3], true)}
          onMouseLeave={() => setHoverFX(currColor, currPage, false)}
          onClick={() => setClickFX(3)}
          style={{
            backgroundPosition:
              currPage === pageNames[3] ? '-132px -58px' : '-132px 0px',
            backgroundColor: colors[3],
          }}
          className={currPage === pageNames[3] ? 'active' : 'inactive'}
        />
        <div
          onMouseEnter={() => setHoverFX(colors[4], pageNames[4], true)}
          onMouseLeave={() => setHoverFX(currColor, currPage, false)}
          onClick={() => setClickFX(4)}
          style={{
            backgroundPosition:
              currPage === pageNames[4] ? '-198px -58px' : '-198px 0px',
            backgroundColor: colors[4],
          }}
          className={currPage === pageNames[4] ? 'active' : 'inactive'}
        />
        <div
          onMouseEnter={() => setHoverFX(colors[5], pageNames[5], true)}
          onMouseLeave={() => setHoverFX(currColor, currPage, false)}
          onClick={() => setClickFX(5)}
          style={{
            backgroundPosition:
              currPage === pageNames[5] ? '-264px -58px' : '-264px 0px',
            backgroundColor: colors[5],
          }}
          className={currPage === pageNames[5] ? 'active' : 'inactive'}
        />
        <div
          onMouseEnter={() => setHoverFX(colors[6], pageNames[6], true)}
          onMouseLeave={() => setHoverFX(currColor, currPage, false)}
          onClick={() => setClickFX(6)}
          style={{
            backgroundPosition:
              currPage === pageNames[6] ? '-330px -58px' : '-330px 0px',
            backgroundColor: colors[6],
          }}
          className={currPage === pageNames[6] ? 'active' : 'inactive'}
        />
        <span id="wheretogo" style={{ color: bgColor }} data-text={currPage}>
          {pageName}
        </span>
      </Navigation>

      <header>
        <input type="checkbox" className="nav-toggle" id="nav-toggle" />
        <nav>
          <ul>
            <li>
              <button onClick={() => setClickFX(0)} href="#">
                new!
              </button>
            </li>
            <li>
              <button onClick={() => setClickFX(1)} href="#">
                info
              </button>
            </li>
            <li>
              <button onClick={() => setClickFX(2)} href="#">
                releases
              </button>
            </li>
            <li>
              <button onClick={() => setClickFX(3)} href="#">
                projects
              </button>
            </li>
            <li>
              <button onClick={() => setClickFX(4)} href="#">
                calendar
              </button>
            </li>
            <li>
              <button onClick={() => setClickFX(5)} href="#">
                links
              </button>
            </li>
            <li>
              <button onClick={() => setClickFX(6)} href="#">
                contact
              </button>
            </li>
          </ul>
        </nav>
        <label htmlFor="nav-toggle" className="nav-toggle-label">
          {' '}
          <span />
        </label>
      </header>
    </QueryClientProvider>
  ) : (
    <Home click={Enter} />
  )
}
export default Nav
