import React, { useEffect, useRef } from 'react'
import useSWR, { SWRConfig } from 'swr'
import { CenteredPage, UL } from '../styled.js'
import Marquee from '../Announcements/'

//TODO: too much prop drilling! store!
//
function randomNumberInRange(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
const fetcher = (...args) => fetch(...args).then((res) => res.json())

//fetching data
const Gigs = (props) => {
  const color = props.color
  const url = '/data/calendar.json?data=' + randomNumberInRange(0, 100)
  //const url = 'http://localhost/suddeninfant/back/data/gigs.json'
  const { data, error } = useSWR(url, fetcher)
  const bottomRef = useRef(null)
  const topRef = useRef(null)

  const scrollDown = () =>
    bottomRef.current.scrollIntoView({ behavior: 'smooth' })

  const scrollUp = () => topRef.current.scrollIntoView({ behavior: 'smooth' })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (error) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>Error: {error.toString()}</h1>
      </CenteredPage>
    )
  }
  if (!data) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>loading...</h1>
      </CenteredPage>
    )
  }
  return (
    <>
      {/*
        style={{backgroundImage: 'url(/img/sudden_infant.svg)'}}
      */}
      <UL color="rgb(153, 204, 153)">
        <Marquee />
        <span
          className="nav"
          onMouseEnter={scrollDown}
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            zIndex: '999999999',
          }}
        >
          <svg
            stroke={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            width="50"
            height="50"
          >
            <polygon points="0,0 250,350 500,0" strokeWidth="80" />
          </svg>
        </span>

        <span
          className="nav"
          onMouseEnter={scrollUp}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: '999999999',
          }}
        >
          <svg
            stroke={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            width="50"
            height="50"
          >
            <polygon points="0,500 250,150 500,500" strokeWidth="80" />
          </svg>
        </span>
        <div ref={topRef} />
        {data.map((gig, i) => {
          return (
            <li key={i}>
              <span key={gig.date + i}>{gig.date}</span>
              <span key={gig.town + i}>{gig.town}</span>
              <span key={gig.venue + i}>{gig.venue}</span>
              {gig.performers && (
                <span key={gig.performers + i}>{gig.performers}</span>
              )}
              {gig.url && (
                <a href={gig.url} key={gig.url + i} style={{color: 'fuchsia'}} target="_blank">LINK</a>
              )}
            </li>
          )
        })}
        <div ref={bottomRef} />
      </UL>
    </>
  )
}

export default function Live(props) {
  const color = props.color
  return (
    // global provider for swr
    <>
      <Marquee />
      <SWRConfig value={{ fetcher }}>
        <Gigs color={color} />
      </SWRConfig>
    </>
  )
}
