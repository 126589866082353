import React from 'react'
import useSWR, { SWRConfig } from 'swr'
import { CenteredPage, BioPage } from '../styled.js'


  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

const fetcher = (...args) => fetch(...args).then((res) => res.json())
//fetching data
const Biography = () => {
    const url = '/data/info.json?data='+randomNumberInRange(0,100)
  //const url = 'http://localhost/suddeninfant/back/data/info.json'
  const { data, error } = useSWR(url, fetcher)

  //console.log(data, error )
  if (error) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>Error: {error.toString()}</h1>
      </CenteredPage>
    )
  }
  if (!data) {
    return (
      <CenteredPage>
        <img src="http://suddeninfant.com/img/disko.gif" alt="" />
        <h1>loading...</h1>
      </CenteredPage>
    )
  }
  return (
    <BioPage>
      <section>
        <main
          style={{
            backgroundImage: 'url(' + data[0].img + ')',
          }}
        >
          <p className="foto">{data[0].img_text}</p>
        </main>
        <aside>
          <h1>{data[0].name}</h1>
          <p>
            {data[0].artists.map(function (artist) {
              return (
                <span key={artist.name}>
                  {artist.name}
                  <br />
                </span>
              )
            })}
          </p>
          {data[0].text.map(function (item) {
            return <p key={item.paragraph}>{item.paragraph}</p>
          })}
          <br />
          <br />
          {data[0].links.map(function (link) {
            return (
              <span key={link.title}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.title}
                </a>
              </span>
            )
          })}
          {data[0].links.map(function (link) {
            return (
              <span key={link.title}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.title}
                </a>
              </span>
            )
          })}
        </aside>
      </section>

      <section>
        <aside>
          <h1>{data[1].name}</h1>
          <p>{data[1].artists[0].name}</p>

          {data[1].text.map(function (item) {
            return <p key={item.paragraph}>{item.paragraph}</p>
          })}
          <br />
          <br />
          {data[1].links.map(function (link) {
            return (
              <span key={link.title}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.title}
                </a>
              </span>
            )
          })}
          <br />
          <br />
          <div id="shim"></div>
        </aside>

        <main
          style={{
            backgroundImage: 'url(' + data[1].img + ')',
          }}
        >
          <p className="foto">{data[1].img_text}</p>
        </main>
      </section>
    </BioPage>
  )
}
export default function Discogs() {
  return (
    // global provider for swr
    <SWRConfig value={{ fetcher }}>
      <Biography />
    </SWRConfig>
  )
}
